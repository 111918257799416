import { useViewport } from '@lamimed/pantheon';
import dynamic from 'next/dynamic';
import Head from 'next/head';

import { footerImageOffset } from 'components/Footer/styles';
import { Header } from 'components/Header';
import { SectionWrapper } from 'components/wrappers/SectionWrapper';

import { Hero } from 'partials/Hero';

import { AboutUs } from './containers/AboutUs';
import { OurPartners } from './containers/OurPartners';
import { TalkAboutUs } from './containers/TalkAboutUs';

const CheckSintomi = dynamic(
  async () => {
    const mod = await import('./containers/CheckSintomi');

    return mod.CheckSintomi;
  },
  { ssr: false },
);

const Home: React.FC = () => {
  const { greaterThan, lessThan } = useViewport();

  return (
    <>
      <Head>
        <title key="title">
          Lami: visite mediche, videoconsulti online e assistenza domiciliare
        </title>

        <meta
          key="description"
          name="description"
          content="Scopri i servizi di consulenza medica di Lamimed e contatta i migliori esperti comodamente da casa tua. Prenota subito la tua video visita."
        />
      </Head>

      <Header />

      <SectionWrapper
        paddingBottom="none"
        paddingTop="none"
        backgroundColor="neutral_extra_light"
        polygonBackgroundColor="gradient__secondary_diagonal"
        nextSectionContent="Scopri come entrare nel mondo Lami"
        nextSectionId="symptom-checker-section"
        nextSectionButtonVariant={
          lessThan('tablet') ? 'with_content' : undefined
        }
      >
        <Hero />
      </SectionWrapper>

      <SectionWrapper
        paddingTop={greaterThan('tablet') ? 'xxlarge' : 'large'}
        backgroundColor="neutral_extra_light"
        id="symptom-checker-section"
      >
        <CheckSintomi />
      </SectionWrapper>

      <SectionWrapper backgroundColor="neutral_extra_light" paddingTop="none">
        <AboutUs />
      </SectionWrapper>

      <SectionWrapper
        paddingTop={greaterThan('tablet') ? 'xlarge' : 'large'}
        paddingBottom={greaterThan('tablet') ? 'xlarge' : 'xxlarge'}
        backgroundColor="gradient__secondary"
        nextSectionContent="Scopri di più su Lami"
        nextSectionId="categories-section"
        nextSectionButtonVariant={
          lessThan('tablet') ? 'with_content' : undefined
        }
      >
        <OurPartners />
      </SectionWrapper>

      <SectionWrapper
        paddingBottom="xxlarge"
        additionalPadding={footerImageOffset}
      >
        <TalkAboutUs />
      </SectionWrapper>
    </>
  );
};

export default Home;
