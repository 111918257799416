import Router from 'next/router';

import { PARTNER_ID_STORAGE_KEY } from 'hooks/usePartnerId';

import { parseJsonWithDefault } from './parseJsonWithDefault';

export function redirectToSso(lang?: string) {
  const params = new URL(document.location.href).searchParams;
  const queryLang = params.get('lang') || params.get('locale');

  let url = new URL(
    `${process.env.NEXT_PUBLIC_SSO_URL || 'https://auth.lamimed.it'}`,
  );

  if (
    parseJsonWithDefault(
      localStorage.getItem(PARTNER_ID_STORAGE_KEY) as string,
      process.env.NEXT_PUBLIC_DEFAULT_PARTNER_ID,
    ) === process.env.NEXT_PUBLIC_CAPSULA_PUBLIC_PARTNER_ID
  ) {
    url = new URL(
      `${process.env.NEXT_PUBLIC_CAPSULA_URL}/${
        queryLang || lang || 'it'
      }/auth/sign-in`,
    );
  }

  url.searchParams.set('origin', window.location.href);

  url.searchParams.set('lang', queryLang || lang || 'it');

  Router.push(url);
}
